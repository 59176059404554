<template>
  <el-dialog custom-class="manageAndroidChannelPage" title="Android Channels" :visible.sync="visible" width="600px">
    <!-- Add Channel Section -->
    <div class="p-3 flex border-b">
      <el-input class="flex-1" placeholder="Channel Name" v-model="addChannelForm.name"></el-input>
      <div class="pl-2">
        <el-button type="primary" @click="onAddChannel" :loading="savingChanges">Add Channel</el-button>
      </div>
    </div>

    <!-- Active Channel List -->
    <div class="p-3">
      <div class="text-base text-black">Channel List</div>

      <!-- If No Channel -->
      <div v-if="channelList.length === 0" class="mt-1 border py-5 rounded-lg text-center text-gray-500">No channel added yet.</div>

      <!-- Channel List -->
      <div v-else>
        <div class="flex border my-2.5 rounded-md py-2 px-3 flex bg-gray-50 hover:bg-gray-100" v-for="(channel, index) in channelList" :key="index">
          <div class="flex-1 text-black">{{ channel }}</div>
          <div class="">
            <i @click="onRemoveChannel(index)" class="fa fa-trash text-red-600 cursor-pointer"></i>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getCompanySettingByKey, saveCompanySettingByKey } from '@/services/company';

const settingKey = 'android-channels';
export default {
  name: 'addAndroidChannelPopup',
  data() {
    return {
      visible: false,

      addChannelForm: {
        name: ''
      },
      savingChanges: false,

      channelList: []
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },

    showDialog() {
      this.visible = true;
      this.channelList = [];
      this.fetchAndroidChannelList();
    },

    async onAddChannel() {
      // Empty Check
      if (this.addChannelForm.name.trim() == '') {
        this.warningToast('Channel name is required');
        return;
      }

      // Duplicate Check.
      if (this.channelList.includes(this.addChannelForm.name)) {
        this.warningToast('Channel name already exists');
        return;
      }

      this.savingChanges = true;
      try {
        let updatedList = [this.addChannelForm.name, ...this.channelList];

        // Hit Save Api
        await saveCompanySettingByKey({
          key: settingKey,
          type: 'channel-settings',
          subType: 'android-channels',
          settings: JSON.stringify(updatedList)
        });

        this.channelList = updatedList;
        this.addChannelForm.name = '';

        this.$emit('onUpdatedAndroidChannelList', this.channelList);
        this.successToast('Android channel added successfully.');
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to add android channel. Please contact support.');
      } finally {
        this.savingChanges = false;
      }
    },

    async onRemoveChannel(index) {
      try {
        await this.$confirm('Remove Android Channel?', 'Delete', {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Delete Channel',
          cancelButtonText: 'Cancel'
        });

        try {
          let updatedList = JSON.parse(JSON.stringify(this.channelList));
          updatedList.splice(index, 1);

          // Hit Save Api
          await saveCompanySettingByKey({
            key: settingKey,
            type: 'channel-settings',
            subType: 'android-channels',
            settings: JSON.stringify(updatedList)
          });

          this.channelList = updatedList;
          this.addChannelForm.name = '';

          this.$emit('onUpdatedAndroidChannelList', this.channelList);
          this.successToast('Android channel removed successfully.');
        } catch (err) {
          this.reportError(err);
          this.errorToast('Failed to remove android channel. Please contact support.');
        }
      } catch (err) {
        // Igore changes
      }
    },

    async fetchAndroidChannelList() {
      try {
        this.androidChannelList = [];

        let response = await getCompanySettingByKey(settingKey);

        if (response.data) {
          this.channelList = JSON.parse(response.data);
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch android channel list. Please contact support.');
      }
    }
  }
};
</script>

<style lang="scss">
.manageAndroidChannelPage {
  border-radius: 7px !important;
  overflow: hidden;

  .el-dialog__header {
    border-bottom: 1px solid #ddd;
    padding: 15px 10px 10px 20px !important;
    background: #fafafa;
    text-align: left;

    .el-dialog__headerbtn {
      margin-top: -8px;
      font-size: 20px;
    }
  }

  .el-dialog__body {
    padding: 0 !important;
    padding: 10px;

    .mainHeader {
      font-size: 16px;
    }
  }
}
</style>
