import DynamicUserProperty from '@/components/dynamicUserFilterComponent/dynamicUserFilter';
import { getCompanySettingByKey } from '@/services/company';
import campaignPreviewService from '@/services/campaignMessagePreview';
import colorPicker from '@caohenghu/vue-colorpicker';
import AddAndroidChannelPopup from './addAndroidChannelPopup';
import async from 'async';
import { Picker } from 'emoji-mart-vue';
import _ from 'lodash';
import customerModule from '../../../../../services/customer';

export default {
  name: 'mobilePushEditor',
  components: {
    Picker,
    colorPicker,
    DynamicUserProperty,
    AddAndroidChannelPopup
  },
  props: {
    osType: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    },
    isJourneyCampaign: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      contentAlreadyRendered: false,

      // emoji popover variables
      titleEmojiPiker: false,
      messageEmojiPiker: false,

      // dynamicuserFilter popover  variables
      messageDynmicUserPopver: false,
      titleDynmicUserPopver: false,
      imageDynamicPopover: false,
      buttonLinkDynamicPopover: {
        android: [false, false],
        ios: [false, false]
      },
      keyValueDynamicPopover: [false, false, false, false, false],

      mobilePush: {
        title: 'Notification Title Goes Here 😋',
        message: 'Notification Message Goes Here 😂',
        media: {
          type: 'image',
          image: 'https://images.unsplash.com/photo-1573117425812-87a41504bc34',
          crousal: []
        },
        backgroundColor: {
          enabled: false,
          color: '#ffffff'
        }
      },
      options: {
        android: {
          summaryText: 'Shop more, save more!',

          click: {
            action: 'open-app',
            deepLinkUrl: null,
            screenName: null,
            webUrl: null
          },

          // Buttons
          useButtons: false,
          buttons: [],

          // Other Android Settings.
          channel: ''
        },
        ios: {
          actionId: '',
          title: 'iOS',
          summaryText: 'Shop more, save more!',

          click: {
            action: 'open-app',
            deepLinkUrl: null,
            screenName: null,
            webUrl: null
          },

          // Buttons
          useButtons: false,
          category: null,

          // Other Android Settings.
          notificationPriority: 'active'
        }
      },
      keyValuePairEnabled: false,
      keyValuePairList: [],
      // selectAttributeBySearch: null,
      userAttributeToSearchBy: 'email',

      // Form validation
      mobilePushFormRules: {
        title: [
          {
            required: true,
            message: 'Please input notification title',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 300,
            message: 'Length should be 3 to 300',
            trigger: 'blur'
          }
        ],
        message: [
          {
            required: true,
            message: 'Please input notification message',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 1000,
            message: 'Length should be 3 to 1000',
            trigger: 'blur'
          }
        ],
        channel: [
          {
            required: false,
            message: 'Please input android channel',
            trigger: 'blur'
          }
        ]
      },

      // dynamic User variable
      dynamicUserProperty: '',

      // Push Notification Test Variables
      testPushDialog: {
        visible: false,
        selectedUserList: [],
        queryString: '',
        inputOfUser: '',

        errorMessage: null,
        errorList: []
      },

      // Provider Variables
      androidChannelList: [],

      // Preview Content Variables
      isStickyPreviewMode: false,
      isIosPreviewMode: false,
      sendingTestNotification: false
    };
  },

  methods: {
    setDynamicProp(text, inputType) {
      // Fetch Current Position
      let elementId = inputType + 'MPushInput';
      let position = document.getElementById(elementId).selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      if (inputType == 'title') {
        this.mobilePush.title = insert(this.mobilePush.title, position, text);
        this.titleDynmicUserPopver = false;
      } else if (inputType == 'message') {
        this.mobilePush.message = insert(this.mobilePush.message, position, text);
        this.messageDynmicUserPopver = false;
      } else if (inputType == 'image') {
        this.mobilePush.image = insert(this.mobilePush.image, position, text);
        this.commonDynamicPopover = false;
      } else if (inputType.startsWith('button-deeplink')) {
        let info = inputType.split('--')[1];
        let os = info.split('-')[0];
        let index = info.split('-')[1];
        console.log('0------------------------------00', info, os, index);

        let original = this.options[os].buttons[index].deeplinkUrl;
        this.options[os].buttons[index].deeplinkUrl = insert(original, position, text);
        this.options[os].buttons[index].deeplinkPopover = false;
      } else if (inputType.startsWith('key_value_pair')) {
        let index = inputType.split('-')[1];
        let original = this.keyValuePairList[index].value;
        this.keyValuePairList[index].value = insert(original, position, text);
      }
    },

    setColor(obj, property, args) {
      let color = args[0];
      obj[property] = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a).toUpperCase();
    },

    setPreviewMode(os) {
      // console.log('mouse entered');
      this.isIosPreviewMode = os == 'ios';
    },

    handleScroll() {
      let top = document.querySelector('#mobilePushEditorPage').getBoundingClientRect().top;

      if ((top < 87) & !this.isStickyPreviewMode) {
        // console.log('making sticky')
        let el = this.$refs.mobilePreview;
        // Make it sticky
        this.isStickyPreviewMode = true;
        el.classList.add('sticky');
      } else if (top > 87 && this.isStickyPreviewMode) {
        // console.log('unmaking sticky')
        let el = this.$refs.mobilePreview;
        this.isStickyPreviewMode = false;
        el.classList.remove('sticky');
      }
    },

    onTitleEmojiSelected(emoji) {
      this.mobilePush.title += emoji.native;
      this.titleEmojiPiker = false;
    },

    onMessageEmojiSelected(emoji) {
      this.mobilePush.message += emoji.native;
      this.messageEmojiPiker = false;
    },

    addButton(os, buttonsArray) {
      buttonsArray.push({
        label: '',
        click: {
          action: 'open-app',
          deepLinkUrl: null,
          screenName: null,
          webUrl: null
        },
        index: buttonsArray.length,

        // Popover Variables
        deeplinkPopover: false
      });
    },

    removeButton(os, index) {
      console.log('Remove button', os, index);
      this.options[os].buttons.splice(index, 1);
    },

    addKeyValuePair() {
      this.keyValuePairList.push({
        key: '',
        value: '',
        index: this.keyValuePairList.length
      });
    },

    // #region **************************** Start: Android Channel Mgt *********************************/

    async fetchAndroidChannelList() {
      try {
        this.androidChannelList = [];

        let response = await getCompanySettingByKey('android-channels');
        if (response.data) {
          console.log('Android Channel List', response.data);
          this.androidChannelList = JSON.parse(response.data);
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong');
      }
    },

    onShowManageAndroidChannelDialog() {
      this.$refs.AddAndroidChannelPopup.showDialog();
    },

    onUpdatedAndroidChannelList(channelList) {
      this.androidChannelList = channelList.map((item) => {
        return item;
      });
    },

    // #endregion **************************** Finished: Android Channel Mgt *********************************/

    /**************************** Start: Push Notification Test Methods *********************************/

    async showSendTestNotificationDilog() {
      // Validate input first.
      let isValid = await this.validateForm();
      if (!isValid) {
        this.errorToast('Validation Error. Please resolve validation issues.');
        return;
      }
      this.testPushDialog.visible = true;
      this.testPushDialog.errorMessage = null;
    },

    async testPushNotificationContent() {
      let campaignContent = await this.readProcessedContentV1();
      campaignContent = campaignContent.content;

      let params = {
        customerList: this.testPushDialog.selectedUserList,
        pushContent: campaignContent
      };

      this.sendingTestNotification = true;
      this.testPushDialog.errrorMessage = null;
      this.testPushDialog.errorList = [];

      campaignPreviewService
        .testPushNotificationContent(params)
        .then((result) => {
          this.testPushDialog.errorList = [];
          for (let i = 0; i < result.data.length; i++) {
            if (!result.data[i].success) {
              this.testPushDialog.errorList.push(result.data[i]);
            }
          }

          if (this.testPushDialog.errorList.length > 0) {
            this.testPushDialog.errorMessage = `${this.testPushDialog.errorList.length} out of ${result.data.length} sends failed.`;
          } else {
            this.successToast(`Push notification sent.`);
            this.testPushDialog.visible = false;
          }

          this.sendingTestNotification = false;
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast(`Failed to send test notification. Please contact support.`);
          this.sendingTestNotification = false;
        });
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    fetchUserListForPushContentTest(queryString, cb) {
      let params = {
        queryString: queryString,
        customerProp: this.userAttributeToSearchBy,
        pushType: 'mobile'
      };

      let results = [];
      customerModule
        .fetchUserSuggestionListForPushTest(params, this)
        .then((result) => {
          if (result.data.success) {
            results = queryString ? result.data.data.filter(this.createFilter(queryString)) : result.data.data;
          }
          cb(results);
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast(`Something went wrong. Please try again later.`);
          cb(results);
        });
    },

    updateSelectedUserList(item) {
      let user = _.find(this.testPushDialog.selectedUserList, (u) => {
        return u.id == item.id;
      });
      if (!user) {
        this.testPushDialog.selectedUserList.push(item);
      }
      this.testPushDialog.inputOfUser = '';
    },

    /**************************** End: Push Notification Test Methods *********************************/

    validateForm() {
      return new Promise((resolve) => {
        let item = this;

        let showGenericErrorToast = true;
        async.parallel(
          {
            // Mobile Push Form Validation
            mobilePushForm: function (callback) {
              item.$refs['mobilePushForm'].validate((valid) => {
                if (!valid) {
                  return callback('invalid');
                }
                callback();
              });
            },

            // Mobile Push Form Validation
            validateNotificationChannel: function (callback) {
              // if (!item.options.android.channel) {
              //   item.warningToast('Please provide notification channel.');
              //   showGenericErrorToast = false;
              //   callback('Please provide notification channel.');
              // } else

              callback();
            },

            // Advance Android Options Validation
            // androidAdvanceOptionsForm: function (callback) {
            //   if (!item.options.android.enabled) return callback();
            //   item.$refs['advanceOptionsForm'][0].validate((valid) => {
            //     if (!valid) {
            //       return callback('invalid');
            //     }
            //     if (!item.options.android.useButtons) return callback();
            //     async.each(
            //       item.options.android.buttons,
            //       function (button, cb) {
            //         item.$refs['buttonListForm_Android'][button.index].validate((valid) => {
            //           if (!valid) {
            //             return cb('invalid');
            //           }
            //           cb();
            //         });
            //       },
            //       function (err) {
            //         if (err) return callback(err);
            //         callback();
            //       }
            //     );
            //   });
            // },

            // Advance iOS Options Validation
            // iosAdvanceOptionsForm: function (callback) {
            //   if (!item.options.ios.enabled) return callback();
            //   item.$refs['advanceOptionsForm'][1].validate((valid) => {
            //     if (!valid) {
            //       return callback('invalid');
            //     }
            //     if (!item.options.ios.useButtons) return callback();
            //     async.each(
            //       item.options.ios.buttons,
            //       function (button, cb) {
            //         item.$refs['buttonListForm_iOS'][button.index].validate((valid) => {
            //           if (!valid) {
            //             return cb('invalid');
            //           }
            //           cb();
            //         });
            //       },
            //       function (err) {
            //         if (err) return callback(err);
            //         callback();
            //       }
            //     );
            //   });
            // },

            // Key Value Pair List
            keyValuePairListForm: function (callback) {
              if (!item.keyValuePairEnabled) {
                callback();
                return;
              }

              async.each(
                item.keyValuePairList,
                function (elem, cb) {
                  item.$refs['keyValuePairListForm'][elem.index].validate((valid) => {
                    if (!valid) {
                      return cb('invalid');
                    }
                    cb();
                  });
                },
                function (err) {
                  if (err) return callback(err);
                  callback();
                }
              );
            }
          },
          function (err) {
            if (err) {
              if (showGenericErrorToast) {
                item.warningToast('Please resolve input issues.');
              }
              resolve(false);
            } else resolve(true);
          }
        );
      });
    },

    readProcessedContentV1() {
      return {
        content: {
          // Notification Data
          mobile_push_title: this.mobilePush.title,
          mobile_push_message: this.mobilePush.message,
          mobile_push_image: this.mobilePush.image,

          // Android Advance options
          mobile_push_android_options: JSON.stringify(this.options.android),

          // iOS Advance options
          mobile_push_ios_options: JSON.stringify(this.options.ios),

          // Raw Data
          mobile_push_data: JSON.stringify({
            media: this.mobilePush.media,
            backgroundColor: this.mobilePush.backgroundColor,
            keyValuePairs: {
              enabled: this.keyValuePairEnabled,
              keyValuePairList: this.keyValuePairList
            }
          })
        }
      };
    },

    renderCampaignContent(data) {
      if (!this.contentAlreadyRendered) {
        this.contentAlreadyRendered = true;

        this.fetchAndroidChannelList();
        if (!data.content) return null;

        // Notification Data
        this.mobilePush.title = data.content.mobile_push_title;
        this.mobilePush.message = data.content.mobile_push_message;
        // this.mobilePush.image = data.content.mobile_push_image;  //Deprecated
        // Android advance options
        data.content.androidAdvanceOptions = data.content.mobile_push_android_options && typeof data.content.mobile_push_android_options == 'string' ? JSON.parse(data.content.mobile_push_android_options) : this.options.android;
        this.options.android = data.content.androidAdvanceOptions;
        // iOS Advance options
        data.content.iosAdvanceOptions = data.content.mobile_push_ios_options && typeof data.content.mobile_push_ios_options == 'string' ? JSON.parse(data.content.mobile_push_ios_options) : this.options.ios;
        this.options.ios = data.content.iosAdvanceOptions;

        // Raw Data
        let rawData = data.content.mobile_push_data && typeof data.content.mobile_push_data == 'string' ? JSON.parse(data.content.mobile_push_data) : null;
        if (rawData) {
          // Read Media Settings
          this.mobilePush.media = rawData.media;
          // Read Background Color
          this.mobilePush.backgroundColor = rawData.backgroundColor;
          // Set Key Value Pair List
          if (rawData.keyValuePairs) {
            this.keyValuePairEnabled = rawData.keyValuePairs.enabled;
            this.keyValuePairList = rawData.keyValuePairs.keyValuePairList;
          }
        }
      }
      window.requestAnimationFrame(() => {
        this.handleScroll();
      });
    },

    renderCampaignContentForJourney(data) {
      if (data.content) {
        let newContent = {};
        newContent.mobile_push_title = data.content.mobilePushTitle;
        newContent.mobile_push_message = data.content.mobilePushMessage;
        newContent.mobile_push_image = data.content.mobilePushImage;

        newContent.mobile_push_android_options = data.content.androidAdvanceOptions;
        newContent.mobile_push_ios_options = data.content.iosAdvanceOptions;
        newContent.mobile_push_data = data.content.mobilePushKeyValuePairData;

        data.content = newContent;
      }

      this.renderCampaignContent(data);
    }
  },

  mounted() {
    this.fetchAndroidChannelList();

    // Add scroll listener
    let appDiv = document.getElementById('appDiv');
    appDiv.onscroll = this.handleScroll;
  },

  beforeDestroy() {
    // Remove scroll listener
    let appDiv = document.getElementById('appDiv');
    appDiv.onscroll = undefined;
  }
};
